import React from 'react';
// import { lazyLoadWithDimensions } from '../../../util/uiHelpers';
import AliceCarousel from 'react-alice-carousel';

// Copied directly from
// `/node_modules/react-alice-carousel/lib/alice-carousel.css`. The
// copied file is left unedited, and all the overrides are defined in
// the component CSS file below.
import '../../../styles/react-alice-carousel.css';
import { FormattedMessage } from '../../../util/reactIntl';
import { H3, NamedLink } from '../../../components';

import css from './SectionFeatures.module.css';

// class LocationImage extends Component {
//   render() {
//     const { alt} = this.props;
//     return <div className={css.imageContainer}><ResponsiveImage className={css.image} {...this.props} /></div>
//   }
// }

// const LazyImage = lazyLoadWithDimensions(LocationImage);
// const IMAGE_VARIANTS = [
//   'square-xsmall',
//   'square-xsmall2x',
//   'square-small',
//   'square-small2x',
// ];

const SectionFeatures = props => {
  const { data } = props;

  const items = () => {
    if (!data) {
      return '';
    }

    return data.map((item, index) => {
      const { title, avatarImg, img, author, category, price } = item;

      return (
        <NamedLink className={css.itemsWrapper} name="SearchPage" key={index}>
          <div className={css.imageHolder}>
            <img src={img} alt={title} />
          </div>
          <div className={css.dataHolder}>
            <H3 className={css.title}>{title}</H3>
            <div className={css.authorHolder}>
              <img src={avatarImg} alt={title} />
              <span className={css.authorName}>{author}</span>
            </div>
            <div className={css.categoryHolder}>
              <span className={css.categoryLabel}>Category:</span>
              <span className={css.categoryName}>{category}</span>
            </div>
            <div className={css.priceHolder}>
              <span className={css.price}>{price}</span>
            </div>
          </div>
        </NamedLink>
      );
    });
  };

  return (
    <div className={css.itemsCarouselHolder}>
      <h2 className={css.rootTitle}>
        <FormattedMessage id="SectionFeatures.title" />
      </h2>
      <p className={css.rootDescription}>
        <FormattedMessage id="SectionFeatures.subtitle" />
      </p>
      <AliceCarousel
        mouseTrackingEnabled
        items={items()}
        disableDotsControls={true}
        infinite={true}
        responsive={{
          0: {
            items: 1,
          },
          550: {
            items: 2,
          },
          1024: {
            items: 4,
          },
        }}
      />

      <div className={css.buttonHolder}>
        <NamedLink className={css.button} name="SearchPage">
          <FormattedMessage id="SectionFeatures.seeMoreBtn" />
        </NamedLink>
      </div>
    </div>
  );
};

export default SectionFeatures;
