import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { H1, H2, NamedLink } from '../../../components';
import heroImg from './hero-img.png';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const {
    rootClassName,
    className,
    heroImgLink,
    title,
    titleCustom,
    subTitle,
    browseButton,
    linkAddress,
    description,
    nextBrowseButton,
    nextLinkAddress,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.titlesHolder}>
          {title ? (
            <H1 className={css.heroMainTitle}>
              <FormattedMessage
                id={title}
                values={{ custom: titleCustom ? <FormattedMessage id={titleCustom} /> : '' }}
              />
            </H1>
          ) : null}
          {subTitle ? (
            <H2 className={css.heroSubTitle}>
              <FormattedMessage id={subTitle} />
            </H2>
          ) : null}
          {description ? (
            <p className={css.heroDescription}>
              <FormattedMessage id={description} />
            </p>
          ) : null}
          <div className={css.btnSection}>
            {browseButton && linkAddress ? (
              <NamedLink name={linkAddress} className={css.heroButton}>
                <FormattedMessage id={browseButton} />
              </NamedLink>
            ) : null}
            {nextBrowseButton && nextLinkAddress ? (
              <NamedLink name={nextLinkAddress} className={css.heroNextButton}>
                <FormattedMessage id={nextBrowseButton} />
              </NamedLink>
            ) : null}
          </div>
        </div>
        {heroImgLink ? (
          <div className={css.imageHolder}>
            {title ? (
              <H1 className={css.heroMainTitleMobile}>
                <FormattedMessage
                  id={title}
                  values={{ custom: titleCustom ? <FormattedMessage id={titleCustom} /> : '' }}
                />
              </H1>
            ) : null}
            <img className={css.image} src={heroImgLink} alt="background" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

SectionHero.defaultProps = {
  rootClassName: null,
  className: null,
  title: 'SectionHero.title1',
  titleCustom: 'SectionHero.title2',
  subTitle: 'SectionHero.subTitle',
  description: 'SectionHero.description',
  linkAddress: 'SearchPage',
  browseButton: 'SectionHero.browseButton',
  heroImgLink: heroImg,
};

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
